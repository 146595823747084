<template>
    <div class="ytm-container" style="margin-top: 1.5rem">
        <div style="display: flex; margin-bottom: 2rem">
            <div style="display: table; margin-right: 1rem">
                <div style="display: table-cell; vertical-align: middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="ytm-admin-back-button" @click="$router.push('/admin')">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/>
                    </svg>
                </div>
            </div>
            <div style="display: table">
                <p style="display: table-cell; vertical-align: middle; font-size: 2rem; font-weight: 600">
                    Верификация профилей преподавателей
                </p>
            </div>
        </div>
        <p style="margin-bottom: 1.5rem">
            Здесь можно подтверждать или отклонять (желательно, с комментарием) изменения в профилях репетиторов.
        </p>
        <p v-if="!profiles">
            Ожидающих репетиторов не осталось, отличная работа! (Но может быть и остались, перезагрузите страничку)
        </p>
        <div v-else>
            <div v-for="profile in profiles" :key="profile.username">
                <TeacherCard :profile="profile" style="margin-bottom: 2rem" @finished="finish(profile.username)"/>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import axios from "axios";
import {SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import TeacherCard from "@/components/Admin/TeacherApprovals/TeacherCard";

export default {
    name: 'TeacherApprovalsPage',
    components: {TeacherCard},
    data() {
        return {
            profiles: [],
        };
    },
    methods: {
        finish(username) {
            const idx = this.profiles.findIndex(val => { return val.username === username });
            if (idx > -1) {
                this.profiles.splice(idx, 1);
            }
        },
    },
    mounted() {
        this.$store.dispatch('auth/autoLogin').then(() => {
            if (!this.$store.getters['auth/isAdmin']) {
                this.$router.push('/home');
            }
            axios.get(
                SERVICE_USER_PROFILE_URI + '/admin/pending_profiles/get',
                {headers: authHeader()},
            ).then(resp => {
                this.profiles = resp.data.out;
            }).catch(err => {
                console.error(err);
            });
        });
    },
};
</script>

<style scoped>
.ytm-admin-back-button {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
</style>