<template>
  <div class="ytm-teacher-profile-card">
    <div style="width: 100%; display: flex; margin-bottom: 2rem">
      <div style="width: 11rem; padding-right: 3rem">
        <div class="ytm-avatar-wrapper">
          <img
              :src="profile.avatar ? profile.avatar : 'https://t4.ftcdn.net/jpg/04/10/43/77/360_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg'"
              alt="Image"
              class="ytm-avatar"
          />
        </div>
      </div>
      <div style="display: table">
        <div style="display: table-cell; vertical-align: middle">
          <p style="font-size: 1.5rem; font-weight: 500; margin-bottom: 0.2rem">{{profile.firstName}} {{profile.surname}}</p>
          <p style="font-size: 0.8rem; color: #343a40">Репетитор</p>
        </div>
      </div>
    </div>
    <div v-if="brief" style="margin-bottom: 2rem">
      <p class="ytm-text-mm" style="margin-bottom: 1rem">Краткое описание</p>
      <EditorContent :editor="brief.editor" style="padding-left: 1rem; border-left: 3px solid lightgray; margin-bottom: 1rem"/>
      <Input
          v-model="brief.comment"
          type="text"
          placeholder="Ваш комментарий"
          style="margin-bottom: 1rem"
      />
      <p v-if="brief.error_msg" style="margin-bottom: 0.6rem; color: red">
        {{brief.error_msg}}
      </p>
      <div style="display: flex">
        <Button style="margin-right: 1rem" @click="setResult('brief', 'verified')">
          {{brief.working ? 'Обрабатывается...' : 'Все ок!'}}
        </Button>
        <Button style="color: white; background-color: #dc3545; border-color: #dc3545" @click="setResult('brief', 'declined')">
          {{brief.working ? 'Обрабатывается...' : 'На доработку'}}
        </Button>
      </div>
    </div>
    <div v-if="about" style="margin-bottom: 2rem">
      <p class="ytm-text-mm" style="margin-bottom: 1rem">Полное описание</p>
      <EditorContent :editor="about.editor" style="padding-left: 1rem; border-left: 3px solid lightgray; margin-bottom: 1rem"/>
      <Input
          v-model="about.comment"
          type="text"
          placeholder="Ваш комментарий"
          style="margin-bottom: 1rem"
      />
      <p v-if="about.error_msg" style="margin-bottom: 0.6rem; color: red">
        {{about.error_msg}}
      </p>
      <div style="display: flex">
        <Button style="margin-right: 1rem" @click="setResult('about', 'verified')">
          {{about.working ? 'Обрабатывается...' : 'Все ок!'}}
        </Button>
        <Button style="color: white; background-color: #dc3545; border-color: #dc3545" @click="setResult('about', 'declined')">
          {{about.working ? 'Обрабатывается...' : 'На доработку'}}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-3";
import {Document} from "@tiptap/extension-document";
import {Text} from "@tiptap/extension-text";
import {Paragraph} from "@tiptap/extension-paragraph";
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Strike} from "@tiptap/extension-strike";
import {Code} from "@tiptap/extension-code";
import {HardBreak} from "@tiptap/extension-hard-break";
import {BulletList} from "@tiptap/extension-bullet-list";
import {OrderedList} from "@tiptap/extension-ordered-list";
import {ListItem} from "@tiptap/extension-list-item";
import {Link} from "@tiptap/extension-link";
import axios from "axios";
import {SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import {VerificationStatus_OnVerification, VerifiactionStatus_Verified, VerificationStatus_Declined} from "@/util/constants";

export default {
  name: 'TeacherCard',
  components: {EditorContent},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      brief: null,
      about: null,
    };
  },
  methods: {
    setResult(type, verdict) {
      if (!this[type] || this[type].working) {
        return;
      }
      this[type].working = true;
      this[type].error_msg = '';
      const status = verdict === 'verified' ? VerifiactionStatus_Verified : VerificationStatus_Declined;
      if (status === VerificationStatus_Declined && !this[type].comment) {
        this[type].working = false;
        this[type].error_msg = 'Заполните комментарий';
        return;
      }
      const payload = {
        username: this.profile.username,
        [type]: {
          status: status,
          comment: this[type].comment,
        },
      };
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.post(
            SERVICE_USER_PROFILE_URI + '/admin/verification/set',
            payload,
            {headers: authHeader()}
        ).then(resp => {
          this[type].working = false;
          this[type] = null;
          if (this.nothingLeft()) {
            this.$emit('finished');
          }
        }).catch(err => {
          this[type].working = false;
          this[type].error_msg = 'Не удалось сохранить';
          console.error(err);
        });
      });
    },
    nothingLeft() {
      return !this.brief && !this.about;
    },
  },
  mounted() {
    if (this.profile.profile.brief.status === VerificationStatus_OnVerification) {
      this.brief = {
        editor: new Editor({
          content: this.profile.profile.brief.draft,
          extensions: [Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link],
          editable: false,
        }),
        comment: '',
        working: false,
        error_msg: '',
      };
    }
    if (this.profile.profile.about.status === VerificationStatus_OnVerification) {
      this.about = {
        editor: new Editor({
          content: this.profile.profile.about.draft,
          extensions: [Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link],
          editable: false,
        }),
        comment: '',
        working: false,
        error_msg: '',
      };
    }
  },
};
</script>

<style scoped>
.ytm-teacher-profile-card {
  border-radius: 1rem;
  border: 1px solid lightgray;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 3rem 0.5rem 3rem;
}

.ytm-avatar-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.ytm-avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>